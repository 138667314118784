<template>
  <div class="no_result">
      <div class="center_box">
        <i :class="['icon-pub','iconfont',iconUrl]" :style="iconSty"></i>
        <div class="text_sty" :style="textSty">{{noResultText}}</div>
      </div>
  </div>
</template>
<script>
export default {
  props: {
      iconUrl: {
        type: String,
        default: ''
      },
      noResultText: {
        type: String,
        default: ''
      },
      textSty: {
        type: String,
        default: ''
      },
      iconSty:{
        type:String,
        default:''
      }
  },
}
</script>
<style scoped lang="scss">
  .no_result{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center_box{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-pub{
    color: #5E5F72;
  }
  .text_sty{
    color: #5E5F72;
    font-weight: bold;
    margin-top: 14px;
  }
</style>
