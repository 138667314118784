<template>
  <div class="recycle">
    <!-- 清空回收站按钮 -->
    <el-row id="header" style="margin-top: 35px">
      <el-col
        style="float: right; text-align: center; margin-right: 2vw"
        class="header"
        :span="3"
      >
        <el-button @click="Delete('','您确定要清空回收站？')" :disabled="listData.length?false:true" :class="listData.length?'':'opacity'">清空回收站</el-button>
      </el-col>
    </el-row>
    <!-- 回收站内容 -->
    <div class="context">
      <!-- 头部行 -->
      <el-row class="headtitle" :gutter="10">
        <!-- 素材列 -->
        <el-col :span="6">
          <span class="rourse ft">素 材</span>
        </el-col>
        <!-- 格式列 -->
        <el-col :span="3">
          <span class="ft">格 式</span>
        </el-col>
        <!-- 基本信息列 -->
        <el-col :span="4">
          <span class="ft">大小</span>
        </el-col>
        <!-- 删除时间列 -->
        <el-col :span="7">
          <span class="ft">删除时间</span>
        </el-col>
        <!-- 操作列 -->
        <el-col :span="4">
          <span class="ft">操 作</span>
        </el-col>
        <el-divider></el-divider>
      </el-row>
      <!-- 分割线 -->
      <!-- 内容行的容器 -->
      <div class="contextcontainer" ref="scrollContent" v-infinite-scroll="loadMore" :infinite-scroll-immediate="false">
        <!-- 内容行 -->
        <div class="contextItme" v-for="(item,i) in listData" :key="i">
          <!-- 内容 -->
          <el-row id="item" :gutter="10">
            <!-- 素材列 -->
            <el-col :span="6">
              <!-- 视频跟文字 -->
              <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom"  popper-class="recycle_tooltip">
                <span class="metirial_name one_line_ellipsis">{{item.name}}</span>
              </el-tooltip>
            </el-col>
            <!-- 格式列 -->
            <el-col :span="3">
              <span>{{item.format}}</span>
            </el-col>
            <!-- 基本信息列 -->
            <el-col :span="4" style="line-height: 25px">
              <p style="padding-left: 10px">{{item.size | transformByte}}</p>
              <!-- <p style="padding-left: 10px">{{item.resolution}}</p> -->
              <!-- <p style="padding-left: 10px" v-if="item.type == 1">{{item.duration}}</p> -->
            </el-col>
            <!-- 删除时间列 -->
            <el-col :span="7">
              <span>{{item.delete_time | formatTime}}</span>
            </el-col>
            <!-- 操作列 -->
            <el-col :span="4">
              <!-- 还原 -->
              <el-tooltip v-delTabIndex effect="dark" content="恢复" placement="top">
                <img style="width: 16px;height: 16px;margin-left: 30px;cursor: pointer;" src="@/assets/images/return.png" alt="" @click="recoverFile(item.ip_id)"/>
              </el-tooltip>
              <!-- 删除 -->
              <el-tooltip v-delTabIndex effect="dark" content="删除记录" placement="top">
                <img @click="Delete(item.ip_id,'您确定要删除此记录吗？')" style="width: 16px;height: 16px;margin-left: 30px;cursor: pointer;" src="@/assets/images/delete.png" alt=""/>
              </el-tooltip>
            </el-col>
          </el-row>
          <!-- 分割线 -->
          <el-divider></el-divider>
        </div>
        <p v-if="loading" class="load_sty">加载中...</p>
        <p v-if="noMore && listData.length" class="load_sty">没有更多了~</p>
        <noResult v-if="listData.length == 0 && !loading" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
      </div>
    </div>
    <!-- 清空回收站对话框 -->
    <el-dialog
      custom-class="clearAllDialog"
      title="提示"
      :visible.sync="dialogVisible"
      center
      top="30vh"
      width="439px"
    >
      <span class="info">{{ info }}</span>
      <div class="dialog">
        <el-button style="margin-left: 88px" @click="confirm">确 定</el-button>
        <el-button style="margin-left: 57px" @click="dialogVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data() {
    return {
      // 显示隐藏清空对话框
      dialogVisible: false,
      // 对话框文本信息
      info: "",
      // 显示隐藏删除对话框

      listData: [], //用于显示的数据
      loading: false,
      noMore: false,
      busyScroll: false,

      pageCount: 1, //分页请求的页码
      limitCount: 40, //分页请求一次请求的数据条数
      ip_id: ""
    };
  },
  computed: {
    recyclePage:{
      get() {
        return this.$store.state.recycle
      },
      set(val){
          // this.$store.commit('recyclePage', val)
      }

    }
  },
  watch: {
    recyclePage(val) {
      if(val == true){
        this.reload();
      }
    }
  },
  created () {
    this.$store.commit('recyclePage', false)
  },

  mounted () {
    this.getRecycleBinList()
  },
  methods: {
    // 删除按钮事件
    Delete(ip_id,title) {
      this.info = title
      this.dialogVisible = true
      this.ip_id = ip_id
    },
    // 对话框确认事件
    confirm() {
      this.dialogVisible = false;
      this.emptyTrash(this.ip_id)
    },
    loadMore(){
      this.loading = true
      if(!this.busyScroll){
        this.noMore = false
        setTimeout(() => {
            this.pageCount += 1
            this.getRecycleBinList()
          }, 2000)
      }else{
        this.noMore = true
        this.loading = false
      }
    },
    getRecycleBinList () {
      this.$http({
        method: 'GET',
        url: '/vw_material/recycle_bin_list/',
        params: {
          page: this.pageCount,
          page_num: this.limitCount
        }
      }).then(res => {
        if (res.data.status === 0) {
          if(res.data.result.length == 0){
              this.busyScroll = true
              this.noMore  = true
            }
            else {
                this.busyScroll = false
            }

            this.loading = false
            this.listData = this.listData.concat(res.data.result);
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },

    emptyTrash (ip_id) {
      const formData = new FormData()
      formData.append("ip_id",ip_id)
      this.$http({
        method: 'POST',
        url: '/vw_material/empty_the_recycle_bin/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          if(ip_id == "") {
            this.listData = []
          }else{
            this.listData.forEach((item,i)=>{
            if(item.ip_id == ip_id) {
              this.listData.splice(i,1)
              return
            }
          })
          }
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    },
    recoverFile (ip_id) {
      const formData = new FormData()
      formData.append("ip_id",ip_id)
      this.$http({
        method: 'POST',
        url: '/vw_material/restore_ip/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.listData.forEach((item,i)=>{
            if(item.ip_id == ip_id) {
              this.listData.splice(i,1)
              return
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error => console.log(error))
    }


  },
  destroyed (){
    this.$store.commit('recyclePage', false)
  }

};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/mixin.scss';
.recycle {
  // 清空回收站按钮及对话框样式
  #header {
    .el-button {
      width: 96px;
      height: 30px;
      line-height: 30px;
      background: #2D2C39;
      border-radius: 16px;
      font-size: 14px;
      // color: rgba(255, 255, 255, 0.9);
      border: 0px solid #dcdfe6;
      padding: 0;
    }
    .opacity{
      opacity:0.6;
　　　filter:alpha(opacity=60);  //filter 过滤器   兼容IE678
    }
  }
  //回收站内容
  .context {
    // 头部行
    padding: 40px 3.5vw 0 3.2vw;
    overflow: hidden;
    .headtitle {
      text-align: center;
      color: rgba(255, 255, 255, 0.9);
      .el-divider {
        background-color: #3e3d4c;
        margin: 30px 0px 0px 0px;
      }
      .ft{
        font-size: 16px;
      }
    }
    .el-divider--horizontal {
        display: block;
        height: 1px;
        width: 100%;
        margin: 0;
        background: #3e3d4c;
      }
    .load_sty{
      text-align:center;
      color:rgba(255,255,255,0.6);
      margin-top:30px;
    }
    // 内容行的容器
    .contextcontainer {
      overflow-x: hidden;
      height: 70vh;
      font-size: 14px;
      @include scrollbar(6px,7px);
      // 内容行
      .contextItme {
          #item{
            height: 116px;
            display: flex;
            align-items: center;
            text-align: center;
          }
        .el-row {
          text-align: center;
          .metirial_name {
              display: block;
            }
        }
      }
    }
  }
  /deep/.clearAllDialog {
    background: #292939;
    border-radius: 15px;
    box-sizing: content-box;
    // width: 439px !important;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
    height: 226px;
    .el-dialog__header {
      letter-spacing: 30px;
      padding: 8px 0px 0px 13px;
      .el-dialog__title {
        color: rgba(255, 255, 255, 0.9);
      }
    }
    .el-dialog__headerbtn {
      display: none;
    }
    .el-dialog__body {
      padding: 0px;
      color: #606266;
      width: 397px;
      height: 162px;
      background: #0F0F1E;
      margin-top: 7px;
      margin-left: 22px;
      border-radius: 0 0 5px 5px;
      .info {
        padding-top: 45px;
        padding-left: 88px;
        color: rgba(255, 255, 255, 0.9);
        display: block;
        text-align: left;
      }
      .dialog {
        display: flex;
        .el-button {
          width: 80px;
          height: 30px;
          background: #0F0F1E;
          border: 1px solid #5e5f72;
          opacity: 0.86;
          border-radius: 20px;
          margin-top: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(255, 255, 255, 0.9);
        }
      }
    }
  }
}
</style>

<style lang="scss">
// 去掉dialog 的默认padding-right:17px;
.el-popup-parent--hidden{
  padding-right:unset !important;
}
.recycle_tooltip.el-tooltip__popper{
  max-width: 18%;
  line-height: 20px;
}
</style>
